import React, { useState, useEffect } from 'react';
import {Grid, Skeleton} from '@mui/material';
import {Items} from './components'
import axios from 'axios';
// import { appConfig } from './config';

const App = () => {
  const get_json_url = 'https://www.colorstyle.co.jp/json-insta/';
  // const get_json_url = appConfig.baseURL+appConfig.instagram_business_account+appConfig.param+appConfig.token;
  const [post, setPost] = useState([]);
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [profile_picture_url, setProfilePictureUrl] = useState('');
  const skeltons = [0,1,2,3,4,5,6,7,8,9];

  useEffect( () => {
    // console.log('get_json_url',get_json_url);
    axios.get(get_json_url).then((response) => {
      // console.log('response.data',response.data);
      setPost(response.data.media.data);
      setName(response.data.name);
      setUsername(response.data.username);
      setProfilePictureUrl(response.data.profile_picture_url);
    });
  }, [get_json_url]);
  // useEffect( () => {
  //   console.log('profile_picture_url',profile_picture_url)
  // },[profile_picture_url])

  return (
    <Grid container spacing={1}>
      {post.length > 0 && post.map( item => {
        return (
          <Items key={item.id} data={item} name={name} username={username} ppu={profile_picture_url}></Items>
        );
      })}
      {post.length === 0 && skeltons.map( item => {
        return (
          <Grid item xs={6} md={2.4} key={item}>
            <Skeleton variant="rectangular"></Skeleton>
          </Grid>
        )
      })}
    </Grid>
  );
}

export default App;
