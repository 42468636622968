import React from "react";
import {
  Grid, Card, CardHeader, CardActionArea, CardMedia, CardContent,
  Dialog, Avatar, IconButton, Link,
} from "@mui/material";
// import InstagramIcon from '@mui/icons-material/Instagram';
import CloseIcon from '@mui/icons-material/Close';
import '../css/style.min.css';


const Items = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Grid item xs={6} md={2.4}>
        <Card>
          <CardActionArea onClick={handleOpen}>
            <CardMedia
              component="img"
              image={props.data.media_url}
              alt={props.data.caption}
            />
          </CardActionArea>
        </Card>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xl"
      >
        <div className="modal-insta">
          <div className="image">
            <Link href={props.data.permalink} target="_blank" rel="noreferrer"><img src={props.data.media_url} alt="" /></Link>
          </div>
          <div className="description">
            <Card elevation={0}>
              <CardHeader
                avatar={
                  <Avatar src={props.ppu} />
                }
                action={
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                }
                title={props.name}
                subheader={props.username}
              ></CardHeader>
              <CardContent>
                {props.data.caption}
              </CardContent>
            </Card>
          </div>
        </div>
      </Dialog>
    </>
  )
};

export default Items;